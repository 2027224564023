<template>
  <div class="approveTemplate">
    <card-template
      :current-record="currentRecord"
      :creation-date="currentRecord.notificationsend"
      :userkey="currentRecord.username"
      :title="currentRecord.offernumber"
      :actions="{
        openItem: () => launchOffer(currentRecord.refhdl)
      }"
      :icon="icon"
      :buttons="buttons"
    >
      <template #description>
        <p> {{ description }} </p>
      </template>
    </card-template>
  </div>
</template>


<script>
import CardTemplate from "@/components/templates/CardTemplate";
import mixinAkioma from '@/mixins/global-akioma';

export default {
  components: {
    CardTemplate
  },
  mixins: [mixinAkioma],
  props: {
    dataSource: {
      type: Object
    },
    datasourceNamespace: {
      type: Object
    },
    currentRecord: {
      type: Object
    }
  },
  data() {
    return {
      description: "Offer approval requested",
      showFullDescription: false,
      icon: {
        primary: 'far fa-stack-2x fa-file-check'
      },
      buttons: {
        delete: {
          show: false,
          iconClass: 'fas fa-trash-alt'
        },
        open: {
          show: false,
          iconClass: 'fa fa-arrow-up rotate-45'
        }
      }

    };
  },
  computed: {
    isDescriptionTooLong() {
      let description = '';
      if (this.record)
        description = this.record.offerdesc;
      else
        description = this.currentRecord.offerdesc;

      if (description) {
        const charachters = description.split('');
        if (charachters.length > 80)
          return true;

      }
      return false;
    }
  }
};
</script>
