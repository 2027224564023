// define a mixin object
export default {
  methods: {
    /**
     * Get Icon by type
     *
     * @param   {string}  type  Icon class or type of icon
     *
     * @return  {string}        The icon specific to the hardcoded type or the original value
     */
    getIconByType(type) {
      let typeParts = type.split(' ');
      if(typeParts.length > 1)
        return type;
      else
        return 'fad fa-3x ' + type.splice(1).join(' ');
    },
    /**
     * Method for checking if template option is a constant string
     *
     * @param   {string}  templateField  Field or constant string
     *
     * @return  {boolean}                 if it is a constant will return true, false otherwise
     */
    isConstant(templateField) {
      if(templateField.startsWith("'") || templateField.startsWith("\""))
        return true;

      return false;
    },
    /**
     * Method for stripping the single and double quotes from a given string
     *
     * @param   {string}  strValue  String value to strip
     *
     * @return  {string}
     */
    stripQuotes(strValue) {
      return strValue.replace(/["']/g, "");
    }
  },
  computed: {
    /**
     * Computed prop for returning the template options fields, the names of the fields to use in template
     *
     * @return  {array}  [img, key, value]
     */
    templateOptions() {
      let templateOptions = [];
      if (this.$props.controller && this.$props.controller.opt.templateOptions) {
        templateOptions = this.$props.controller.opt.templateOptions.split('|');
        templateOptions = templateOptions.map((templateOption) => templateOption.toLowerCase());
      }
      return templateOptions;
    },
    /**
     * Computed for icon class field name
     *
     * @return  {string}  The icon class
     */
    iconTemplateField() {
      if (this.templateOptions[0])
        return this.templateOptions[0];
    },
    /**
     * Computed for key field name
     *
     * @return  {any}  The name of the key field
     */
    keyTemplateField() {
      if (this.templateOptions[1])
        return this.templateOptions[1];
    },
    /**
     * Computed for desc field name
     *
     * @return  {any}  The name of the desc field
     */
    descTemplateField() {
      if (this.templateOptions[2])
        return this.templateOptions[2];
    },
    /**
     * Computed value of icon templateOption field
     *
     * @return  {string}  The icon class value
     */
    icon() {
      if (!this.isConstant(this.iconTemplateField)) {
        let imgVal = this.currentRecord[this.iconTemplateField];
        imgVal = this.getIconByType(imgVal);
        return imgVal;
      }
      return this.stripQuotes(this.iconTemplateField);
    },
    /**
     * Computed value of key templateOption field
     *
     * @return  {string}  The key field value
     */
    key() {
      if (!this.isConstant(this.keyTemplateField))
        return this.currentRecord[this.keyTemplateField];

      return this.stripQuotes(this.keyTemplateField);
    },
    /**
     * Computed value of desc templateOption field
     *
     * @return  {string}  The desc field value
     */
    desc() {
      if (!this.isConstant(this.descTemplateField))
        return this.currentRecord[this.descTemplateField];

      return this.stripQuotes(this.descTemplateField);
    }
  }
};