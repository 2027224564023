import config from './config';
import screens from './screens/index.js';
import userprofile from './screens/common/userprofile.js';

const state = {
  refresh: ''
};


const mutations = {
  REFRESH(state){
    state.refresh = '';
  }
};

export default {
  state,
  mutations,
  namespaced: true,
  modules: {
    config,
    userprofile,
    screens
  }
};