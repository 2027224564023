import Vue from 'vue';

import store from './store';

import common from './store/modules/swat/screens/common/common.js';
import taskbar from './store/modules/taskbar.js';
import form from './store/modules/swat/screens/common/form.js';
import grid from './store/modules/swat/screens/visual/panel/fieldcontainer/grid/grid.js';
import datasource from './store/modules/swat/screens/data/datasource.js';

import InstancesFactory from './InstancesFactory';


Vue.config.productionTip = false;
if (!global.akioma)
  global.akioma = {};


global.akioma.VuexStore = store;
global.akioma.VuexModules = {};
global.akioma.Vue = Vue;
global.akioma.VueInstancesFactory = InstancesFactory;

// create Taskbar
global.akioma.VueTaskbar = new InstancesFactory('taskbar', {
  store
});

const debug = process.env.NODE_ENV !== 'production';


global.akioma.VuexStore.$app = global.akioma.VueTaskbar;

global.akioma.VuexModules.commonModule = common;
global.akioma.VuexModules.taskbarModule = taskbar;
global.akioma.VuexModules.formModule = form;
global.akioma.VuexModules.gridModule = grid;
global.akioma.VuexModules.dataSourceModule = datasource;


// for development
if (debug){
  // global.akioma.VueTaskbar.$mount('#app');
  // global.akioma.VuexStore.dispatch('taskbar/addSampleData');
}

global.akioma.registerModules = () => {
  // console.log(common);
  // global.akioma.VuexStore.registerModule(['swat','screens','test'], common);
  // global.akioma.VuexStore.commit('swat/REFRESH');

  // global.akioma.VuexStore.registerModule(['swat','screens','test2'], common);
  // global.akioma.VuexStore.commit('swat/REFRESH');
};