// define a mixin object
export default {
  methods: {
    /**
         * Method for returning the custom states of a control
         * @returns {array|null} customStates
         */
    getCustomStates () {
      if (this.$props.controller) {
        let control = this.$props.controller;
        if (this.$props.controller.form)
          control = this.$props.controller.form;

        const customStates = control._getters('getCustomStates', this.$props.controller.opt.id);
        return customStates;
      }
      return null;
    },
    /**
         * Method for returning a given custom state if found. Returns null otherwise
         * @returns {object|null} customState
         */
    getCustomState (name) {
      if (this.$props.controller) {
        let control = this.$props.controller;
        if (this.$props.controller.form)
          control = this.$props.controller.form;

        const customState = control._getters('getCustomState', { id: this.$props.controller.opt.id , name });
        return customState;
      }
      return null;
    }
  }
};