<template>
  <div class="generic-card-template">
    <div>
      <i :class="icon" />
    </div>
    <p><b>{{ key }}</b><br>{{ desc }}</p>
  </div>
</template>

<script>
import mixinAkioma from '@/mixins/global-akioma';
import mixinTemplateOptions from '@/mixins/template-options';

export default {
  mixins: [ mixinAkioma, mixinTemplateOptions ],
  props: {
    dataSource:{
      type: Object
    },
    datasourceNamespace: {
      type: Object
    },
    currentRecord: {
      type: Object
    },
    controller: {
      type: Object
    }
  }
};
</script>