<template>
  <div class="vue-dataview">
    <template v-for="el in items">
      <component :is="template"
                 :key="el._id"
                 :data-source="dataSource"
                 :controller="controller"
                 :current-record="el"
                 :cursor="cursor"
                 @click.native="onItemClickEvent(el._id)"
      />
    </template>
  </div>
</template>
<script>
import * as components from '@/components/templates';
import { importDynamicTemplates } from '../helpers/dynamicImportHelper';
let externalComponents = {};
importDynamicTemplates().then(res => externalComponents = res);

export default {
  components: {
    ...components
  },
  props: {
    dataSource: {
      type: Object,
      required: true
    },
    controller: {
      type: Object
    },
    datasourceNamespace: {
      type: Object,
      required: true
    },
    onItemClick: {
      type: Function
    },
    onItemDblClick: {
      type: Function
    },
    template: {
      type: String
    }
  },
  data: () => {
    return {
      delay: 350,
      clicks: 0,
      timer: null
    };
  },
  computed: {
    items() {
      if (!this.$props.dataSource)
        return [];

      const name = this.$props.datasourceNamespace.slice();
      name.push('getCursor');

      let records = [];
      const currIndex = this.$store.getters[name.join('/')];
      if (currIndex !== null && currIndex !== '') {
        if(this.$props.dataSource.dhx && this.$props.dataSource.dhx.data)
          records = Object.values(this.$props.dataSource.dhx.data.pull);
      }

      return records;

    },
    cursor() {
      if (!this.$props.datasourceNamespace)
        return null;

      const name = this.$props.datasourceNamespace.slice();
      name.push('getCursor');

      const currIndex = this.$store.getters[name.join('/')];

      return currIndex;
    }
  },
  created() {
    this.$options.components = {
      ...this.$options.components,
      ...externalComponents
    };
  },
  methods: {
    onItemClickEvent(id) {
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          // simple click
          if (this.onItemClick !== undefined)
            this.onItemClick(id);

          this.clicks = 0;
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        this.onItemDblClickEvent(id);
        this.clicks = 0;
      }

    },
    onItemDblClickEvent(id) {
      if (this.onItemDblClick !== undefined)
        this.onItemDblClick(id);

    },
    item() {
      if (!this.$props.dataSource)
        return [];

      const name = this.$props.datasourceNamespace.slice();
      name.push('getCursor');

      const currIndex = this.$store.getters[name.join('/')];
      if (currIndex !== null && currIndex !== '') {
        const record = this.$props.dataSource.dhx.item(currIndex);
        return record;
      } else
        return [];

    }
  }

};
</script>