<template>
  <card-template
    :active="isSelected"
    :current-record="currentRecord"
    :creation-date="currentRecord.aktimecreated"
    :userkey="currentRecord.usercreatedkey"
    :title="currentRecord.cfilename"
    :actions="actions"
    :icon="icon"
    :buttons="buttons"
    :class="'externaldoc-template'"
  >
    <template #header_info>
      <p class="externaldoc-template__creation-date">
        {{ getDateFormated(currentRecord.aktimecreated) }}
      </p>
      <p class="externaldoc-template__creator">
        by
        <strong>{{ currentRecord.usercreatedkey }}</strong>
      </p>
      <p class="externaldoc-template__group">
        Group: {{ currentRecord.groupdesc }}
      </p>
    </template>
    <template #description />
    <template #aditional_info>
      <p class="externaldoc-template__filename">
        <strong>{{ currentRecord.cfilename }}</strong>
      </p>
    </template>
  </card-template>
</template>

<script>
import mixinAkioma from "@/mixins/global-akioma";
import CardTemplate from "@/components/templates/CardTemplate";

export default {
  components: {
    CardTemplate
  },
  mixins: [mixinAkioma],
  props: {
    dataSource: {
      type: Object
    },
    datasourceNamespace: {
      type: Object
    },
    currentRecord: {
      type: Object
    },
    cursor: {
      type: String
    },
    controller: {
      type: Object
    }
  },
  data() {
    return {
      actions: {
        openItem: () => this.openItem()
      },
      buttons: {
        open: {
          show: true,
          iconClass: 'fa fa-arrow-up rotate-45'
        }
      },
      icon: {
        primary: 'fal fa-stack-2x small-icon fa-file'
      }
    };
  },
  computed: {
    isSelected() {
      return this.$props.cursor === this.currentRecord._id;
    },
    isWordType() {
      if (this.currentRecord && this.currentRecord.cfilename) {
        const splitData = this.currentRecord.cfilename.split('.');
        if (splitData.length < 1)
          return false;
        const fileExt = splitData[1];
        switch (fileExt) {
          case 'doc':
          case 'docx':
            return true;
        }
      }
      return false;
    }
  }
};
</script>