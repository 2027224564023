<template>
  <div ref="taskbar" :class="{ 'disabled-taskbar': isDisabled, 'vue-taskbar': true }">
    <!-- content section -->
    <div ref="viewArea" class="container">
      <div ref="contentArea" class="content-area">
        <!-- taskbar groups -->
        <div class="taskbar-groups">
          <TaskbarGroup v-for="group in taskbarGroups" :key="group.id" :item="group"
                        :inactive-width="inactiveGroupWidth"
                        :has-changes="hasChangesGroup(group.id)" :has-errors="hasErrorsGroup(group.id)" :custom-states="hasCustomStateGroup(group.id)"
          />
        </div>

        <!-- taskbar items / of selected group -->
        <div class="active-taskbar-items">
          <TaskbarItem v-for="item in taskbarItems" :key="item.id" :item="item"
                       :inactive-width="inactiveItemWidth"
                       :has-changes="hasChangesItem(item.id)" :has-errors="hasErrorsItem(item.id)" :custom-states="hasCustomStateItem(item.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import TaskbarGroup from './TaskbarGroup.vue';
import TaskbarItem from './TaskbarItem.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'TaskbarMenu',
  components: {
    TaskbarGroup,
    TaskbarItem
  },
  props: {
    close: Function,
    closeGroup: Function,
    select: Function
  },
  data(){
    return {
      containerView: null,
      inactiveGroupWidth: 250,
      inactiveItemWidth: 250
    };
  },
  computed: {
    ...mapGetters({
      taskbarGroups: 'taskbar/taskbarGroups',
      activeTaskbarGroupID: 'taskbar/getActiveGroup',
      getEnabled: 'taskbar/getEnabled',
      getItemsByGroup: 'taskbar/taskbarItems'
    }),
    ...mapState('taskbar', {
      taskbarGroupSize: 'taskbarGroupSize',
      taskbarItemSize: 'taskbarItemSize'
    }),
    taskbarItems(){
      return this.getItemsByGroup(this.activeTaskbarGroupID);
    },
    isDisabled(){
      return !this.getEnabled;
    }
  },
  watch: {
    isDisabled: function (val){
      this.calcInactiveSizes();
    }
  },
  created(){

    this.$root.$on('taskbar:closeItem', (item) => {
      if (this.$props.close)
        this.$props.close(item);

    });
    this.$root.$on('taskbar:selectItem', (item) => {
      if (this.$props.select)
        this.$props.select(item);

    });
    this.$root.$on('taskbar:closeGroup', (group, items) => {
      if (this.$props.closeGroup)
        this.$props.closeGroup(group, items);

    });

  },
  mounted(){
    this.containerView = this.$refs.contentArea;
    this.addTaskbarResizeObserver();
    this.addInactiveResizeEvents();
  },
  methods: {
    addTaskbarResizeObserver(){

      const observer = new ResizeObserver(entries => {
        entries.forEach((entry) => {
          this.calcInactiveSizes();
        });
      });

      const child = this.$refs.taskbar;
      observer.observe(child);

    },
    addInactiveResizeEvents() {
      this.$root.$on('taskbar:closeItem', (item) => {
        // console.log('taskbar:closeItem');
        this.calcInactiveSizes();
      });

      this.$root.$on('taskbar:closeGroup', (group, items) => {
        // console.log('taskbar:closeGroup');
        this.calcInactiveSizes();
      });

      this.$root.$on('taskbar:addItem', (item) => {
        // console.log('taskbar:addItem');
        this.calcInactiveSizes();
      });


      this.$root.$on('taskbar:selectItem', (item) => {
        // console.log('taskbar:selectItem');
        this.calcInactiveSizes();
      });
    },
    getMaxWidthToFit() {
      const taskbarElmWidth = this.$refs.taskbar.offsetWidth;
      const taskbarGroupsLength = (this.isDisabled ? this.taskbarGroups.length : this.taskbarGroups.length - 1);
      const taskbarItemsLength = (this.isDisabled ? this.taskbarItems.length : this.taskbarItems.length - 1);
      const maxGroups = (taskbarGroupsLength * this.taskbarGroupSize) - (3 * taskbarGroupsLength);
      const maxItems = (taskbarItemsLength * this.taskbarItemSize) - (3 * taskbarItemsLength);

      return Math.max(maxGroups, taskbarElmWidth, maxItems);
    },
    calcInactiveSizes() {
      const taskbarElmWidth = this.$refs.taskbar.offsetWidth;
      const maxToFit = this.getMaxWidthToFit();

      // console.log(`Element’s size: width: ${this.$refs.taskbar.offsetWidth} , height: ${this.$refs.taskbar.offsetHeight}`);
      // console.log(`content area’s size: width: ${this.$refs.contentArea.offsetWidth} , height: ${this.$refs.contentArea.offsetHeight}`);
      // console.log(`maxtofit: ${maxToFit}`);
      // console.log(`taskbarElmWidth: ${taskbarElmWidth}`);

      if (maxToFit >= taskbarElmWidth) {
        this.calcInactiveGroupSize(taskbarElmWidth - 10);
        this.calcInactiveItemSize(taskbarElmWidth - 10);
      } else
        this.resetInactiveSizes();


    },
    isResizable(){
      return this.$refs.taskbar.offsetWidth;
    },
    calcInactiveGroupSize(containerSize){
      let inactiveGroupSize = this.taskbarGroupSize;
      let inactiveGroups = this.taskbarGroups.length;

      if (!this.isDisabled)
        inactiveGroups -= 1;

      if (this.isDisabled)
        inactiveGroupSize = containerSize / inactiveGroups;
      else if (this.taskbarGroups.length > 1)
        inactiveGroupSize = ( containerSize - this.taskbarGroupSize ) / inactiveGroups;


      // console.log(`Size of inactive group taskbar is : ${inactiveGroupSize}`);

      this.inactiveGroupWidth = inactiveGroupSize;
    },
    calcInactiveItemSize(containerSize) {
      let inactiveItemSize = this.taskbarItemSize;
      let inactiveItems = this.taskbarItems.length;
      if (!this.isDisabled)
        inactiveItems -= 1;


      if (this.isDisabled)
        inactiveItemSize = containerSize / inactiveItems;
      else if (inactiveItemSize > 1)
        inactiveItemSize = ( containerSize - this.taskbarItemSize ) / inactiveItems;


      // console.log(`Size of inactive item taskbar is : ${inactiveItemSize}`);

      this.inactiveItemWidth = inactiveItemSize;
    },
    resetInactiveSizes(){
      this.inactiveGroupWidth = this.taskbarGroupSize;
      this.inactiveItemWidth = this.taskbarItemSize;
    },
    getItemType(item){
      if (item.type == 'group')
        return 'TaskbarGroup';
      else
        return 'TaskbarItem';

    },

    closeItemEvent(item){

      if (this.$props.close)
        this.$props.close(item);

    },
    selectItemEvent(item){
      if (this.$props.select)
        this.$props.select(item);

    },
    hasChangesItem(id){
      return this.$store.getters['taskbar/getHasChangesItem'](id);
    },
    hasCustomStateItem(id){
      return this.$store.getters['taskbar/getCustomStateItem'](id);
    },
    hasErrorsItem(id){
      return this.$store.getters['taskbar/getHasErrorsItem'](id);
    },
    hasChangesGroup(id){
      return this.$store.getters['taskbar/getHasChangesGroup'](id);
    },
    hasCustomStateGroup(id){
      return this.$store.getters['taskbar/getCustomStateGroup'](id);
    },
    hasErrorsGroup(id){
      return this.$store.getters['taskbar/getHasErrorsGroup'](id);
    }
  }
};
</script>
