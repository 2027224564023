<template>
  <div class="panel-msg">
    <PanelMessage v-for="(msg, index) in getPanelMSG"
                  :key="index" :msg="msg" :current-record="currentRecord"
                  :controller="controller"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PanelMessage from './PanelMessage.vue';

export default {
  components: {
    PanelMessage
  },
  props: {
    namespace: Array,
    datasourceNamespace: Array,
    dataSource: Object,
    controller: Object
  },
  computed: {
    currentRecord() {
      if (!this.$props.dataSource)
        return [];

      const name = this.$props.datasourceNamespace.slice();
      name.push('getCursor');

      const currIndex = this.$store.getters[name.join('/')];
      return this.$props.dataSource.dhx.item(currIndex);
    },
    getPanelMSG() {
      var name = this.$props.namespace.slice();
      name.push('getPanelMSG');
      if ( typeof(this.$store.getters[name.join('/')]) !== 'undefined' )
        return this.$store.getters[name.join('/')]();
      else
        return [];

    }
  }
};
</script>