import common from './common.js';

import Vue from 'vue';

const state = () => {
  return {
    ...common.state(),
    hasError: false,
    children: {},
    fieldsChanged: [],
    formInitialEnabled: null,
    type: 'form'
  };

};


const mutations = {
  ...common.mutations,
  ADD_FORM_FIELD(state, field){
    if (field && field.userdata && field.userdata.id)
      Vue.set(state.children, field.userdata.id, field);

  },
  SET_HAS_CHANGES_FIELD(state, { hasChanges, id }){
    const index = state.fieldsChanged.indexOf(id);

    if (hasChanges) {
      if (index === -1)
        state.fieldsChanged.push(id);

    } else if (index > -1)
      state.fieldsChanged.splice(index, 1);

    state.children[id].hasChanges = hasChanges;
  },
  SET_HAS_ERRORS_FIELD(state, { hasErrors, id }){
    state.children[id].hasErrors = hasErrors;
  },
  SET_ENABLED_FIELDS(state, bEnabled){

    for (let c in state.children){
      let child = state.children[c];
      child.enabled = bEnabled;
    }
  },
  SET_ENABLED(state, enabled){
    state.attributes.enabled = enabled;
    for (let c in state.children){
      let child = state.children[c];
      if (child.enabledParent)
        child.enabledParent = enabled;

      child.enabled = enabled;
    }
  },
  SET_INITIAL_ENABLED(state, enabled){
    // console.log('SET_INITIAL_ENABLED', enabled)

    state.formInitialEnabled = enabled;
  },
  SET_ENABLED_FIELD(state, { enabled, id }){
    if (state.children[id]){
      state.children[id].enabled = enabled;
      if (state.children[id].enabledParent)
        state.children[id].enabledParent = enabled;

    }
  },
  SET_MANDATORY(state, { mandatory, id }){
    if (state.children[id])
      state.children[id].mandatory = mandatory;

  },
  SET_ERROR_FIELD(state, { hasError, id, msg }){
    if (state.children[id]){
      state.children[id].hasError = hasError;
      state.children[id].errorMsg = msg;
    }
  },
  SET_ERROR(state, hasError) {
    state.hasError = hasError;
  },
  SET_FORM_FIELD_CUSTOM_STATE(state, {id, name, options, namespace}) {
    if (!state.children[id].customStates)
      Vue.set(state.children[id], 'customStates', []);

    let alreadyAdded = false;
    for (let index = 0; index < state.children[id].customStates.length; index++) {
      if (state.children[id].customStates[index].name == name)
        alreadyAdded = true;

    }

    if (!alreadyAdded) {
      state.children[id].oldCustomStates = JSON.parse(JSON.stringify(state.children[id].customStates));
      state.children[id].customStates.push({id, name, options, namespace});
    }
  },
  CLEAR_FORM_FIELD_CUSTOM_STATE(state, {id, name}) {
    const field = state.children[id];

    if (field && field.customStates) {
      field.oldCustomStates = JSON.parse(JSON.stringify(state.children[id].customStates));
      for (let index = 0; index < field.customStates.length; index++) {
        if (field.customStates[index].name == name)
          field.customStates.splice(index, 1);

      }
    }
  }
};

const actions = {
  ...common.actions,
  setFormFieldDirty({ state, commit, dispatch }, { value }){
    commit('SET_HAS_CHANGES_FIELD', { hasChanges: value.hasChanges, id: value.id });
  },
  setFormFieldEnabled({ state, commit, dispatch }, { value }){
    commit('SET_ENABLED_FIELD', { enabled: value.enabled, id: value.id });
  },
  setEnabled({ state, commit, dispatch }, { value }){
    commit('SET_ENABLED', value.enabled);
    // commit('SET_ENABLED_FIELDS', value.enabled);
  },
  setFormFieldMandatory({ state, commit, dispatch }, { value }){
    commit('SET_MANDATORY', { mandatory: value.mandatory, id: value.id });
  },
  setFormFieldErrorState({ state, getters, commit, dispatch }, { value }){
    // set form field error state
    let errorData = { hasError: value.hasError, id: value.id };
    if (value.msg !== undefined)
      errorData.msg = value.msg;


    commit('SET_ERROR_FIELD', errorData);

    let aFieldsWithErrors = getters.getFormFieldsError();
    let hasErrors = (aFieldsWithErrors.length > 0);

    // if there are multiple fields with errors set form control state of hasError
    if (hasErrors == true)
      commit('SET_ERROR', true);
    else if (aFieldsWithErrors.length == 0)
      commit('SET_ERROR', false);


  },
  setFormEnabled({ state, commit, dispatch }, { value }){

    if (value.enabled !== null && state.formInitialEnabled == null)
      commit('SET_INITIAL_ENABLED', value.enabled);

    else if (state.formInitialEnabled !== null && value.enabled === null)
      commit('SET_INITIAL_ENABLED', null);


    dispatch('setEnabled', { value: { enabled: value.enabled } });

  },
  setFormFieldCustomState({commit}, {value, namespace}) {
    value.namespace = namespace;
    commit('SET_FORM_FIELD_CUSTOM_STATE', value);
  },
  clearFormFieldCustomState({commit}, {value}) {
    commit('CLEAR_FORM_FIELD_CUSTOM_STATE', value);
  }

};

const getters = {
  ...common.getters,
  getFormFieldState: (state) => ( id ) => {
    const field = state.children[id];

    if (field)
      return field.hasChanges;


    return false;
  },
  getFormFieldEnabled: (state) => (id) => {
    const field = state.children[id];

    // console.log('field',field, state.formInitialEnabled, field.enabledParent);

    if (field.enabledParent !== null)
      return field.enabledParent;
    else if (field)
      return field.enabled;


    return false;

  },
  getFormFieldsDirty: (state) => () => {
    let aFieldIds = [];
    for (let i in state.children){
      let field = state.children[i];

      if (field.hasChanges)
        aFieldIds.push(field.name);

    }

    return aFieldIds;
  },
  getFormFieldMandatory: (state) => (id) => {
    const field = state.children[id];

    if (field)
      return field.mandatory;


    return false;
  },
  getFormFieldsMandatory: (state) => () => {
    let aFieldIds = [];
    for (let i in state.children){
      let field = state.children[i];

      if (field.mandatory)
        aFieldIds.push(field.name);

    }

    return aFieldIds;
  },
  getFormFieldError: (state) => (id) => {
    const field = state.children[id];

    if (field)
      return field.hasError;


    return false;
  },
  getFormFieldErrorMsg: (state) => (id) => {
    const field = state.children[id];

    if (field)
      return field.errorMsg;


    return false;
  },
  getFormFieldsError: (state) => () => {
    let aFieldIds = [];

    for (let i in state.children){
      let field = state.children[i];

      if (field.hasError)
        aFieldIds.push(field.name);

    }

    return aFieldIds;
  },
  getCustomStates: (state) => (id) => {
    const field = state.children[id];

    if (field)
      return field.customStates;

  },
};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
