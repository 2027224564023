<template>
  <div
    ref="groupEl"
    :key="item.id"
    :style="{ width: inactiveWidthPixels }"
    :class="{
      'haschanges': hasChanges,
      'haserrors': hasErrors,
      'vue-taskbar-group': true,
      'defaultgroup': isDefaultGroup,
      'active': isActive,
      'inactive': !isActive
    }" :akcustomstate="customStates" @click="setActiveGroup"
  >
    <div class="vue-taskbar-group-content">
      <i
        v-if="item.icon"
        :class="item.icon"
      />
      <span :title="item.title" v-html="item.title" />
      <CloseIcon @click.native.stop="onClose()" />
    </div>
  </div>
</template>

<script>

import CloseIcon from './CloseIcon.vue';
import { mapState } from 'vuex';

export default {
  name: 'TaskbarGroup',
  components: {
    CloseIcon
  },
  props: {
    item: Object,
    hasChanges: Boolean,
    hasErrors: Boolean,
    customStates: String,
    inactiveWidth: {
      type: Number,
      default: 250
    }
  },
  data(){
    return {
      width: 'auto',
      groupEl: null
    };
  },
  computed: {
    ...mapState('taskbar', {
      taskbarGroupSize: 'taskbarGroupSize'
    }),
    isActive(){
      return this.$store.getters['taskbar/isGroupActive'](this.item.id);
    },
    isDefaultGroup(){
      return this.$store.getters['taskbar/defaultGroup'] == this.item.id;
    },
    children(){
      return this.$store.getters['taskbar/getItemsForGroup'](this.item.id);
    },
    inactiveWidthPixels(){
      if (!this.isActive)
        return this.inactiveWidth + 'px';

      return this.taskbarGroupSize + 'px';
    }
  },
  mounted(){
    this.groupEl = this.$refs.groupEl;
  },
  methods: {
    setActiveGroup(){
      this.$store.commit('taskbar/SET_ENABLED', true);
      this.$store.commit('taskbar/SET_ACTIVE_GROUP', this.item.id);

      const activeItem = this.$store.getters['taskbar/getActiveItemInGroup'](this.item.id);
      if (activeItem)
        this.$root.$emit('taskbar:selectItem', activeItem);

    },
    onClose(){
      const childrenItems = this.$store.getters['taskbar/getItemsForGroup'](this.item.id);
      this.$root.$emit('taskbar:closeGroup', this.item, childrenItems);
    },
    onCloseEvent(item){
      this.$root.$emit('taskbar:closeItem', item);
    }

  }


};
</script>

