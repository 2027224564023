// define a mixin object
export default {
  methods: {
    /**
         * Method for launching owner with Child positioning
         * @param {string} cScreen
         * @param {string} cOwnerHdl
         * @param {string} cChildHdl
         */
    launchOwnerWithChild(cScreen, cOwnerHdl, cChildHdl){
      window.app.controller.launchContainer( {
        containerName: cScreen,
        data: true,
        extLink: cOwnerHdl,
        pages: "1,2",
        self: this.$props.controller,
        customData: {
          childHdl: cChildHdl
        }
      });
    },

    /**
         * Method used for translations
         * @param {string} text
         * @param {string} defaultTranslat
         */
    translateText(text, defaultTranslat) {
      return akioma.tran(text, {defaultValue: defaultTranslat});
    },

    /**
         * Launch offer by a selfhdl
         * @param  {string} refhdl
         */
    launchOffer(refhdl) {

      app.controller.launchContainer( {
        self:	this,
        proc:	'',
        para:	"SelfHdl=" + refhdl + "&Page=0,1",
        extLink:	refhdl,
        data:	true

      });
    },

    /**
         * Returns a date in front-end format
         * @param  {string} date
         * @return {string}
         */
    getDateFormated(date) {
      return akioma.getFrontendDateFormat(date);
    }
  }
};