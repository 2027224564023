import Vue from 'vue';

const state = {
  currentUser: '',
  currentClient: '',
  app: {
    sessionData: {
      // ...
    }
  }
};
const getters = {
  getCurrentUser(state){
    return state.currentUser;
  },
  getCurrentClient(state){
    return state.currentClient;
  },
  getSessionData(state){
    return state.app.sessionData;
  }
};

const mutations = {
  SET_CURRENT_USER(state, currentUser){
    state.currentUser = currentUser;
  },
  SET_CURRENT_CLIENT(state, currentClient){
    state.currentClient = currentClient;
  },
  SET_SESSION_DATA(state, sessionData){
    Vue.set(state, 'sessionData', sessionData);
  }
};

export default {
  namespaced: true,
  getters,
  mutations,
  state
};