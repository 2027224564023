<template>
  <div :class="{
         'active': isSelected,
         'haschanges': hasChanges,
         'haserrors': hasErrors,
         'externalScreenOpened': hasExternalScreenOpened
       }" :style="{ width: inactiveWidthPixels }" class="vue-taskbar-item"
       :akcustomstate="customStates"
       @click="selectItem()"
  >
    <i v-if="item.icon" :class="item.icon" />
    <a :title="item.title" href="#" @click="prevent">
      <span v-html="item.title" />
    </a>
    <CloseIcon @click.native.stop="onClose()" />
  </div>
</template>
<script>
import CloseIcon from './CloseIcon.vue';
import { mapState } from 'vuex';
export default {
  name: 'TaskbarItem',
  components: {
    CloseIcon
  },
  props: {
    item: Object,
    color: String,
    parentid: String,
    hasChanges: Boolean,
    hasErrors: Boolean,
    customStates: String,
    inactiveWidth: Number
  },
  computed: {
    ...mapState('taskbar', {
      taskbarItemSize: 'taskbarItemSize'
    }),
    isSelected(){
      return this.$store.getters['taskbar/isSelected'](this.item.id);
    },
    hasExternalScreenOpened(){
      return this.$store.getters['taskbar/hasExternalScreenOpened'](this.item.id);
    },
    inactiveWidthPixels(){
      // console.log('isSelected', this.isSelected);
      if (!this.isSelected)
        return this.inactiveWidth + 'px';

      return this.taskbarItemSize + 'px';
    }
  },
  created(){
    this.$root.$emit('taskbar:addItem', this.item);
  },
  methods: {
    onClose(){
      this.$root.$emit('taskbar:closeItem', this.item);
    },
    selectItem(){
      this.$store.commit('taskbar/SET_ENABLED', true);
      this.$store.dispatch('taskbar/selectItem', this.item.id);
    },
    prevent(e){
      e.preventDefault();
    }
  }
};
</script>
