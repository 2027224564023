<template>
  <div v-if="currentRecord.firstname" class="contactTemplate">
    <header>
      <p>{{ currentRecord.busreldesc }}</p>
    </header>
    <img :src="imageUrl" :alt="fullName">
    <div class="info">
      <h2 @click="launchOwnerWithChild('orgaW', currentRecord.organisationhdl, currentRecord.structhdl)">
        {{ fullName }}
      </h2>
      <p class="function">
        {{ currentRecord.officialfunction }}
      </p>
    </div>
    <div class="divider" />
    <div class="card">
      <i class="fas fa-phone" />
      <a href="tel:"><b>(032) 444 442</b></a>
      <p>Mobile</p>
    </div>
    <div class="divider" />
    <div class="card">
      <i class="fas fa-envelope" />
      <b><a href="mailto:hello@akioma.de">hello@akioma.de</a></b>
      <p>Personal Email</p>
    </div>
    <div class="divider" />
    <div class="card">
      <i class="far fa-globe" />
      <b><a href="http://www.google.com" target="_blank">www.google.com</a></b>
      <p>Official website</p>
    </div>
  </div>
</template>


<script>
import mixinAkioma from '@/mixins/global-akioma';
export default {
  mixins: [ mixinAkioma ],
  props: {
    dataSource: {
      type: Object
    },
    datasourceNamespace: {
      type: Object
    },
    currentRecord: {
      type: Object
    }
  },
  data(){
    return {
      placeholderImgUrl: '/source-imgs/templates/Portrait.png'
    };
  },
  computed: {
    fullName(){
      if (this.currentRecord.firstname || this.currentRecord.lastname)
        return `${this.currentRecord.firstname} ${this.currentRecord.lastname}`;
      else
        return '';

    },
    imageUrl(){
      if (this.currentRecord.akimagefilename === '' || this.currentRecord.akimagefilename === null)
        return this.placeholderImgUrl;
      else
        return this.currentRecord.akimagefilename;

    }
  }
};
</script>