<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    msg: Object,
    currentRecord: Object,
    control: Object,
    controller: Object
  },
  computed: {
    text(){
      return this.msg.text;
    }
  },
  render (h) {

    const comp = {
      props: {
        currentRecord: {
          type: Object,
          default: this.$props.currentRecord
        },
        controller: {
          type: Object,
          default: this.$props.controller
        }
      },
      template: `<p class="msg-${this.$props.msg.type}">${this.text}<p>`,
      methods: {
        callAkiomaCode(event){
          window.app.controller.callAkiomaCode(this.$props.controller, event);
        }
      }
    };

    return h(comp, {});
  }
};
</script>