const loadExternalComponents = async () => {
  const res = await import(
    /* webpackChunkName: "external-templates" */ process.env.VUE_APP_TEMPLATE_PATH
  );
  return res;
};

export function importDynamicTemplates() {
  return new Promise((resolve, reject) => {
    // load template components dynamically from external path
    if (process.env.VUE_APP_TEMPLATE_PATH) {
      loadExternalComponents().then(res => {
        resolve(res);
      });
    }
  });

}