<template>
  <card-template
    :active="isSelected"
    :current-record="currentRecord"
    :creation-date="currentRecord.aktimecreated"
    :userkey="currentRecord.usercreatedkey"
    :title="currentRecord['artikel-nr']"
    :actions="actions"
    :icon="icon"
    :buttons="buttons"
    :class="'article-template'"
  >
    <template #header_info>
      <h5 class="article-template__desc">
        {{ currentRecord.fulldesc }}
      </h5>
      <p class="base-template__key">
        {{ currentRecord['artikel-nr'] }}
      </p>
      <p class="article-template__attributeflags">
        {{ currentRecord.attributeflags }}
      </p>
    </template>
    <template #aditional_info>
      <div v-if="articleImage" class="base-template__image">
        <img :src="articleImage" alt="Image">
      </div>
    </template>
  </card-template>
</template>

<script>
import mixinAkioma from "@/mixins/global-akioma";
import CardTemplate from "@/components/templates/CardTemplate";

export default {
  components: {
    CardTemplate
  },
  mixins: [mixinAkioma],
  props: {
    dataSource: {
      type: Object
    },
    datasourceNamespace: {
      type: Object
    },
    currentRecord: {
      type: Object
    },
    cursor: {
      type: String
    },
    controller: {
      type: Object
    }
  },
  data() {
    return {
      placeholderImage: '/img/placeholder.png',
      actions: {
      },
      buttons: {
      },
      icon: {
        primary: 'fa fa-stack-2x small-icon fa-shopping-cart'
      }
    };
  },
  computed: {
    isSelected() {
      return this.$props.cursor === this.currentRecord._id;
    },
    articleImage() {
      if (this.currentRecord.itempicture)
        return this.currentRecord.itempicture;


      return this.placeholderImage;
    }
  },
  methods: {
  }
};
</script>