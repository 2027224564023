import children from './children.js';
import Vue from 'vue';

const state = () => {
  return {
    attributes: {
      enabled: false,
      visible: true,
      hasChanges: false,
      hasErrors: false,
      changes: 0,
      errors: 0,
      hasChangesStyle: '',
      panelMSG: [],
      hasErrorsStyle: '',
      customStates: [],
      customStateChanges: 0
    }
  };

};

const mutations = {
  SET_ENABLED(state, enabled){
    state.attributes.enabled = enabled;
  },
  SET_VISIBLE(state, visible){
    state.attributes.visible = visible;
  },
  SET_HASCHANGES(state, hasChanges){
    state.attributes.hasChanges = hasChanges;
  },
  SET_HASERRORS(state, hasErrors){
    state.attributes.hasErrors = hasErrors;
  },
  INCREMENT_CHANGES(state, value){
    state.attributes.changes += value;
  },
  DECREMENT_CHANGES(state, value){
    state.attributes.changes -= value;
  },
  INCREMENT_ERRORS(state, value){
    state.attributes.errors += value;
  },
  DECREMENT_ERRORS(state, value){
    state.attributes.errors -= value;
  },
  SET_HASCHANGES_STYLE(state, hasChangesStyle){
    state.attributes.hasChangesStyle = hasChangesStyle;
  },
  SET_PANEL_MSG(state, {text, type, options}) {
    state.attributes.panelMSG.push({text, type, options});
  },
  REMOVE_PANEL_MSG_BY_ID(state, id) {
    state.attributes.panelMSG.splice(id, 1);
  },
  CLEAR_PANEL_MSG(state) {
    state.attributes.panelMSG = [];
  },
  SET_HASERRORS_STYLE(state, hasErrorsStyle){
    state.attributes.hasErrorsStyle = hasErrorsStyle;
  },
  SET_CUSTOM_STATE_FIELD(state, {id, name, options}) {
    if (!state.children[id].customStates)
      Vue.set(state.children[id], 'customStates', []);

    let alreadyAdded = false;
    for (let index = 0; index < state.children[id].customStates.length; index++) {
      if (state.children[id].customStates[index].name == name)
        alreadyAdded = true;

    }

    if (!alreadyAdded) {
      state.children[id].oldCustomStates = JSON.parse(JSON.stringify(state.children[id].customStates));
      state.children[id].customStates.push({name, options});
    }
  },
  SET_CUSTOM_STATE(state, {id, name, options, namespace}) {
    if (!state.attributes.customStates)
      Vue.set(state.attributes, 'customStates', []);

    let alreadyAdded = false;
    for (let index = 0; index < state.attributes.customStates.length; index++) {
      if (state.attributes.customStates[index].name == name)
        alreadyAdded = true;

    }

    if (!alreadyAdded) {
      state.attributes.oldCustomStates = JSON.parse(JSON.stringify(state.attributes.customStates));
      state.attributes.customStates.push({id, name, options, namespace});
    }
  },
  CLEAR_CUSTOM_STATE_FIELD(state, {id, name}) {
    const field = state.children[id];

    if (field && field.customStates) {
      field.oldCustomStates = JSON.parse(JSON.stringify(state.children[id].customStates));
      for (let index = 0; index < field.customStates.length; index++) {
        if (field.customStates[index].name == name)
          field.customStates.splice(index, 1);

      }
    }
  },
  CLEAR_CUSTOM_STATE(state, {id, name}) {
    const field = state.attributes;

    if (field && field.customStates) {
      field.oldCustomStates = JSON.parse(JSON.stringify(field.customStates));
      for (let index = 0; index < field.customStates.length; index++) {
        if (field.customStates[index].name == name)
          field.customStates.splice(index, 1);

      }
    }
  },
  CLEAR_ALL_CUSTOM_STATES(state) {
    for (const property in state.children) {
      if (!state.children[property].customStates)
        Vue.set(state.children[property], 'customStates', []);

      state.children[property].oldCustomStates = [];
      state.children[property].customStates = [];
    }
  },
  INCREMENT_CUSTOM_STATE(state, {getters, value, payload}) {
    const customState = getters.getCustomStateByName(value);
    if (customState) {
      if (!customState.customStateChanges)
        customState.customStateChanges = 0;

      customState.customStateChanges += payload;
    }
  },
  DECREMENT_CUSTOM_STATE(state, {getters, value, payload}) {
    const customState = getters.getCustomStateByName(value);
    if (customState) {
      if (!customState.customStateChanges)
        customState.customStateChanges = 0;

      customState.customStateChanges -= payload;
    }
  }
};

const getters = {
  getVisible: (state) => () => {
    return state.attributes.visible;
  },
  hasChanges: (state) => () => {
    return state.attributes.hasChanges;
  },
  hasChildChanges: (state) => () => {

    function deepSearch(state){
      for (var y in state) {
        if (state.attributes.hasChanges)
          return true;
        else if (state.children){
          var hasChanges = deepSearch(state.children);
          return hasChanges;
        }
      }
    }

  },
  getPanelMSG: (state) => () => {
    return state.attributes.panelMSG;
  },
  getCustomStates: (state) => (id) => {
    const field = state.children[id];

    if (field)
      return field.customStates;

  },
  getCustomState: (state) => ({ id, name}) => {
    const field = state.children[id];

    if (field && field.customStates) {
      const customState = field.customStates.find((state) => {
        return state.name === name;
      });

      return customState;
    }
    return null;
  },
  getCustomStateByName: (state) => ({name}) => {
    const field = state.attributes;

    if (field && field.customStates) {
      const customState = field.customStates.find((state) => {
        return state.name === name;
      });

      return customState;
    }
    return null;
  },
  getCustomStateWithNamespace: (state) => ({id, name, namespace}) => {
    const field = state.attributes;

    if (field && field.customStates) {
      const customState = field.customStates.find((state) => {
        let compareNamespace1 = state.namespace.join('');
        let compareNamespace2 = namespace.join('');
        if (id) {
          return state.name === name &&
                        compareNamespace1 === compareNamespace2 &&
                        state.id === id;
        } else
          return state.name === name && compareNamespace1 === compareNamespace2;

      });

      return customState;
    }
    return null;
  }
};

const actions = {
  setHasChangesStyle({ state, commit, dispatch }, { value, namespace }){
    commit('SET_HASCHANGES_STYLE', value);
    // console.log(namespace);
    for (let x in state.children){
      let newnamespace = namespace.slice();
      newnamespace.splice(namespace.length - 1, 0, x);
      // console.log('namespace');
      // console.log(namespace);
      // console.log('newnamespace');
      // console.log(newnamespace);


      dispatch(newnamespace.join('/'), {
        value,
        namespace: newnamespace
      } ,{root: true});
    }
  },
  decrementHasChanges({ state, commit, dispatch }, { value, namespace }){
    let initialChanges = -1;

    // only decrement if larger than 0
    if (state.attributes.changes > 0){
      initialChanges = state.attributes.changes;
      commit('DECREMENT_CHANGES', value);
    }
    // if no more changes then set hasChanges to false
    if (state.attributes.changes == 0){
      commit('SET_HASCHANGES', false);

      if (state.type == 'form') {
        for (var c in state.children)
          commit('SET_HAS_CHANGES_FIELD', { hasChanges: false, id: c });

      }

    }

    // if there are changes and if namespace
    if (initialChanges > 0 && namespace.length > 4){

      let newnamespace = namespace.splice(0,namespace.length - 2);
      newnamespace.push(namespace[namespace.length-1]);

      dispatch(newnamespace.join('/'), {
        value,
        namespace: newnamespace
      } ,{root: true});
    }

  },
  incrementHasChanges({ commit, dispatch }, { value, namespace }){


    commit('INCREMENT_CHANGES', value);

    if (namespace.length > 4){
      let newnamespace = namespace.splice(0,namespace.length - 2);
      newnamespace.push(namespace[namespace.length-1]);

      dispatch(newnamespace.join('/'), {
        value,
        namespace: newnamespace
      } ,{ root: true });
    }

  },

  setHasChanges({ state, commit, dispatch }, { value, namespace}){

    commit('SET_HASCHANGES', value);

    if (namespace.length > 4){

      let newnamespace = namespace.splice(0,namespace.length - 2);
      newnamespace.push(namespace[namespace.length-1]);

      dispatch(newnamespace.join('/'), {
        value,
        namespace: newnamespace
      } ,{ root: true });
    }

  },

  setHasErrorsStyle({ state, commit, dispatch }, { value, namespace }){
    commit('SET_HASERRORS_STYLE', value);

    for (let x in state.children){
      let newnamespace = namespace.slice();
      newnamespace.splice(namespace.length - 1, 0, x);

      dispatch(newnamespace.join('/'), {
        value,
        namespace: newnamespace
      } ,{root: true});
    }
  },

  decrementHasErrors({ state, commit, dispatch }, { value, namespace }){
    let initialErrors = -1;

    // only decrement if larger than 0
    if (state.attributes.errors > 0){
      initialErrors = state.attributes.errors;
      commit('DECREMENT_ERRORS', value);
    }
    // if no more changes then set hasChanges to false
    if (state.attributes.errors == 0){
      commit('SET_HASERRORS', false);

      if (state.type == 'form') {
        for (var c in state.children)
          commit('SET_HAS_ERRORS_FIELD', { hasChanges: false, id: c });

      }

    }

    // if there are changes and if namespace
    if (initialErrors > 0 && namespace.length > 4){

      let newnamespace = namespace.splice(0,namespace.length - 2);
      newnamespace.push(namespace[namespace.length-1]);

      dispatch(newnamespace.join('/'), {
        value,
        namespace: newnamespace
      } ,{root: true});
    }

  },
  incrementHasErrors({ commit, dispatch }, { value, namespace }){

    commit('INCREMENT_ERRORS', value);

    if (namespace.length > 4){
      let newnamespace = namespace.splice(0,namespace.length - 2);
      newnamespace.push(namespace[namespace.length-1]);

      dispatch(newnamespace.join('/'), {
        value,
        namespace: newnamespace
      } ,{ root: true });
    }

  },
  setHasErrors({ state, commit, dispatch }, { value, namespace}){

    commit('SET_HASERRORS', value);

    if (namespace.length > 4){

      let newnamespace = namespace.splice(0,namespace.length - 2);
      newnamespace.push(namespace[namespace.length-1]);

      dispatch(newnamespace.join('/'), {
        value,
        namespace: newnamespace
      } ,{ root: true });
    }

  },
  setPanelMSG({commit}, {value} ) {
    if (value.text && value.type)
      commit('SET_PANEL_MSG', value);

  },
  removePanelMsgById({commit}, {value}) {
    commit('REMOVE_PANEL_MSG_BY_ID', value);
  },
  clearPanelMsg({commit}) {
    commit('CLEAR_PANEL_MSG');
  },
  setCustomState({getters, commit, dispatch}, {value, namespace}) {
    value.namespace = namespace;
    if (!getters.getCustomStateWithNamespace(value)) {
      commit('SET_CUSTOM_STATE', value);
      commit('INCREMENT_CUSTOM_STATE', {getters, value, payload: 1});

      if (value.options && value.options.bubbleUp) {

        if (namespace.length > 4){
          const newnamespace = namespace.slice(0,namespace.length - 2);
          newnamespace.push(namespace[namespace.length-1]);

          dispatch(newnamespace.join('/'), {
            value,
            namespace: newnamespace
          } ,{ root: true });
        }
      }
    }

  },
  clearCustomState({getters, state, commit, dispatch}, {value, namespace}) {
    let initialChanges = -1;

    const customState = getters.getCustomStateByName(value);
    if (!customState)
      return;


    if (!customState.customStateChanges)
      customState.customStateChanges = 0;


    // only decrement if larger than 0
    if (customState.customStateChanges > 0){
      initialChanges = customState.customStateChanges;
      commit('DECREMENT_CUSTOM_STATE', {getters, value, payload: 1});
    }
    // if no more changes then set hasChanges to false
    if (customState.customStateChanges == 0)
      commit('CLEAR_CUSTOM_STATE', value);


    if (initialChanges > 0 && namespace.length > 4){
      const newnamespace = namespace.slice(0,namespace.length - 2);
      newnamespace.push(namespace[namespace.length-1]);

      dispatch(newnamespace.join('/'), {
        value,
        namespace: newnamespace
      } ,{ root: true });
    }
  },
  clearAllCustomStates({commit}) {
    commit('CLEAR_ALL_CUSTOM_STATES');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {
    children
  }
};
