<template>
  <div class="home-desktop-template-container">
    <div v-if="!logoError" class="LogoDiv">
      <picture>
        <img class="logo-client"
             :src="clientLogo"
             @error="setLogoErrorStatus()"
        >
      </picture>
    </div>
    <div class="NameDiv">
      <p class="paragraph-client">
        <spanc v-html="clientName" />
      </p>
    </div>
    <div v-if="!imgError" class="ImageDiv">
      <picture>
        <img class="img-client"
             :src="clientImage"
             @error="setImageErrorStatus()"
        >
      </picture>
    </div>
    <div class="TextDiv">
      <p class="paragraph-client">
        <spanc v-html="clientText" />
      </p>
    </div>
  </div>
</template>

<script>
import mixinAkioma from "@/mixins/global-akioma";

export default {
  mixins: [mixinAkioma],
  props: {
    dataSource: {
      type: Object,
      required: true
    },
    datasourceNamespace: {
      type: Object,
      required: true
    },
    currentRecord: {
      type: Object
    },
    cursor: {
      type: String
    },
    controller: {
      type: Object
    }
  },
  data() {
    return {
      clientText: "",
      clientName: "",
      clientLogo:"",
      clientImage:"",
      imgError: false,
      logoError: false
    };
  },
  watch: {
    currentRecord: function (val){
      this.fetchText();
      this.fetchImages();
    }
  },
  methods: {
    setImageErrorStatus(status = true) {
      this.imgError = status;
    },
    setLogoErrorStatus(status = true) {
      this.logoError = status;
    },
    fetchText(){
      $.ajax({
        type: 'GET',
        context: this,
        url: akioma.sessionContext.eSwatSessionContext.hostURL + "/web/AppData/text/" + this.currentRecord.selfhdl,
        data: "type=txt.clt.main"
      }).then(function (result) {
        if (result !== ""){
          this.clientText = result;
        }
      });

      this.clientName = this.currentRecord.selfdesc;
    },
    fetchImages(){
      this.clientLogo = akioma.sessionContext.eSwatSessionContext.hostURL + "/web/AppData/image/" + this.currentRecord.selfhdl + '?type=' + 'img.clt.logo';
      this.setLogoErrorStatus(false);
      this.clientImage = akioma.sessionContext.eSwatSessionContext.hostURL + "/web/AppData/image/" + this.currentRecord.selfhdl + '?type=' + 'img.clt.main';
      this.setImageErrorStatus(false);
    }
  }
};
</script>