var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.item.id,ref:"groupEl",class:{
    'haschanges': _vm.hasChanges,
    'haserrors': _vm.hasErrors,
    'vue-taskbar-group': true,
    'defaultgroup': _vm.isDefaultGroup,
    'active': _vm.isActive,
    'inactive': !_vm.isActive
  },style:({ width: _vm.inactiveWidthPixels }),attrs:{"akcustomstate":_vm.customStates},on:{"click":_vm.setActiveGroup}},[_c('div',{staticClass:"vue-taskbar-group-content"},[(_vm.item.icon)?_c('i',{class:_vm.item.icon}):_vm._e(),_c('span',{attrs:{"title":_vm.item.title},domProps:{"innerHTML":_vm._s(_vm.item.title)}}),_c('CloseIcon',{nativeOn:{"click":function($event){$event.stopPropagation();return _vm.onClose()}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }