import Vue from 'vue';

import PanelMessages from './components/PanelMessages.vue';

let registeredInstancesFactories = {};

registeredInstancesFactories['panel-messages'] = class PanelMessagesFactory {
  constructor({ store, el, namespace, datasourceNamespace, dataSource, controller}) {
    return new Vue({
      store: global.akioma.VuexStore,
      el: el,
      components: {
        PanelMessages
      },
      render: (createElement) => {
        return createElement(
          "PanelMessages",
          {
            props: {
              namespace,
              datasourceNamespace,
              dataSource,
              controller
            }
          }
        );
      }
    });
  }
};

import App from './App.vue';

registeredInstancesFactories['taskbar'] = class TaskbarFactory {
  constructor({ store, }) {
    return new Vue({
      store: global.akioma.VuexStore,
      components: {
        App
      },
      propsData: {
        close: (item) => {
          if (global.akioma.VueTaskbar.onClose)
            global.akioma.VueTaskbar.onClose(item);


        },
        closeGroup: (group, items) => {
          if (global.akioma.VueTaskbar.onCloseGroup)
            global.akioma.VueTaskbar.onCloseGroup(group, items);


        },
        select: (item) => {
          if (global.akioma.VueTaskbar.onSelect)
            global.akioma.VueTaskbar.onSelect(item);

        }
      },
      render: (createElement) => {
        return createElement(
          "App",
          {
            attrs: {
              close: function(item) {
                if (global.akioma.VueTaskbar.onClose)
                  global.akioma.VueTaskbar.onClose(item);


              },
              select: (item) => {
                if (global.akioma.VueTaskbar.onSelect)
                  global.akioma.VueTaskbar.onSelect(item);

              },
              closeGroup: (group, items) => {
                if (global.akioma.VueTaskbar.onCloseGroup)
                  global.akioma.VueTaskbar.onCloseGroup(group, items);


              }
            }
          }
        );
      }
    });
  }
};
import HtmlTemplate from './controls/HtmlTemplate.vue';

registeredInstancesFactories['template'] = class TemplateFactory {
  constructor({ store, el, namespace, datasourceNamespace, dataSource, template, controller }) {
    return new Vue({
      store: global.akioma.VuexStore,
      el: el,
      components: {
        HtmlTemplate
      },
      render: (createElement) => {
        return createElement(
          "HtmlTemplate",
          {
            props: {
              namespace,
              datasourceNamespace,
              dataSource,
              template,
              controller
            }
          }
        );
      }
    });
  }
};


import DataView from './controls/DataView.vue';

registeredInstancesFactories['DataView'] = class TemplatesFactory {
  constructor({ store, el, namespace, datasourceNamespace, controller, dataSource, onItemClick, onItemDblClick, template }) {
    return new Vue({
      store: global.akioma.VuexStore,
      el: el,
      components: {
        DataView
      },
      render: (createElement) => {
        return createElement(
          "DataView",
          {
            props: {
              namespace,
              controller,
              datasourceNamespace,
              dataSource,
              onItemClick,
              onItemDblClick,
              template
            }
          }
        );
      }
    });
  }
};

export default class InstancesFactory {
  constructor (type, options) {
    return new registeredInstancesFactories[type]( options );
  }
}