<template>
  <div class="vue-htmlcontainer">
    <component :is="template" :controller="controller" :current-record="item" />
  </div>
</template>
<script>
import * as components from "@/components/templates";
import { importDynamicTemplates } from '../helpers/dynamicImportHelper';
let externalComponents = {};
importDynamicTemplates().then(res => externalComponents = res);

export default {
  components: {
    ...components
  },
  props: {
    dataSource: {
      type: Object
    },
    datasourceNamespace: {
      type: Array
    },
    template: {
      type: String
    },
    controller: {
      type: Object
    }
  },
  computed: {
    item() {
      if (!this.$props.dataSource)
        return [];

      const name = this.$props.datasourceNamespace.slice();
      name.push("getCursor");

      const currIndex = this.$store.getters[name.join("/")];
      if (currIndex !== null && currIndex !== "" && currIndex !== undefined)
        return this.$props.dataSource.dhx.item(currIndex);
      else
        return [];

    }
  },
  created() {
    this.$options.components = {
      ...this.$options.components,
      ...externalComponents
    };
  }
};
</script>