
import common from '@/store/modules/swat/screens/common/common.js';

const state = () => {
  return {
    ...common.state(),
    changedRows: []

  };
};

const mutations = {
  ...common.mutations,
  ADD_CHANGED_ROW(state, rowID){
    const index = state.changedRows.indexOf(rowID);
    if (index == -1)
      state.changedRows.push(rowID);

  },
  REMOVE_CHANGED_ROW(state, rowID){
    const index = state.changedRows.indexOf(rowID);
    state.changedRows.splice(index, 1);
  },
  CLEAR_CHANGED_ROWS(state){
    state.changedRows = [];
  }
};

const actions = {
  ...common.actions
};

const getters = {
  ...common.getters
};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};