<template>
  <div class="base-template" :class="className">
    <div class="base-template__container">
      <slot name="icon" :icon="icon">
        <span class="fa-stack base-template__icon">
          <i :class="icon.primary" />
          <i v-if="icon.secondary" :class="icon.secondary" />
        </span>
      </slot>

      <slot v-if="currentRecord" name="header_info" :currentRecord="currentRecord">
        <p class="base-template__creation-date">
          {{ getDateFormated(creationDate) }}
        </p>
        <p class="base-template__creator">
          by {{ userkey }}
        </p>
        <h4 class="base-template__key">
          {{ title }}
        </h4>
      </slot>

      <div class="base-template__description">
        <slot v-if="currentRecord" name="description" :currentRecord="currentRecord" />
      </div>

      <slot name="aditional_info" :currentRecord="currentRecord" />

      <slot name="buttons" :actions="actions">
        <div class="base-template__buttons">
          <i v-if="buttons.open" :class="buttons.open.iconClass" @click="actions.openItem()" />
          <i v-if="buttons.delete" :class="buttons.delete.iconClass" @click="actions.deleteItem()" />
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import mixinAkioma from '@/mixins/global-akioma';

export default {
  mixins: [mixinAkioma],
  props: {
    active: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Object
    },
    buttons: {
      type: Object
    },
    currentRecord: {
      type: Object
    },
    creationDate: {
      type: Date
    },
    userkey: {
      type: String
    },
    title: {
      type: String
    },
    actions: {
      type: Object,
      default: () => {
        return {
          deleteItem: () => this.deleteItem(),
          openItem: () => this.openItem()
        };
      }
    },
    class: {
      type: String,
      default: ''
    }
  },
  computed: {
    className () {
      let resClass = '';
      const extraPropClass = this.class;
      if (this.active)
        resClass = 'active ';

      resClass += extraPropClass;

      return resClass;
    }
  },
  methods: {
    deleteItem() {
      this.$props.dataSource.dhx.delete(this.$props.currentRecord._id);
    },
    openItem() {
      this.$props.dataSource.dhx.setCursor(this.$props.currentRecord._id);
      this.$props.controller.rowDblClick(this.$props.currentRecord._id);
    }
  }
};
</script>