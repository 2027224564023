<template>
  <card-template
    :active="isSelected"
    :current-record="currentRecord"
    :creation-date="currentRecord.aktimecreated"
    :title="currentRecord.targetkey"
    :actions="actions"
    :icon="icon"
    :buttons="buttons"
    :class="'offer-template'"
  >
    <template #header_info>
      <p class="base-template__creation-date">
        {{ getDateFormated(currentRecord.aktimecreated) }}
      </p>
      <p class="base-template__creator">
        by
        <strong>{{ currentRecord.usercreatedkey }}</strong>
      </p>
      <span class="base-template__key">
        Offer: <h5>{{ currentRecord.targetkey }}</h5>
      </span>
    </template>
    <template #aditional_info>
      <p class="offer-template__desc">
        {{ shortDesc }}
      </p>
      <p class="offer-template__cust">
        Customer: <strong>Customer Name</strong>
      </p>
      <p class="offer-template__status">
        Status: <strong>Offer Stats</strong>
      </p>
    </template>
  </card-template>
</template>

<script>
import mixinAkioma from "@/mixins/global-akioma";
import CardTemplate from "@/components/templates/CardTemplate";

export default {
  components: {
    CardTemplate
  },
  mixins: [mixinAkioma],
  props: {
    dataSource: {
      type: Object
    },
    datasourceNamespace: {
      type: Object
    },
    currentRecord: {
      type: Object
    },
    cursor: {
      type: String
    },
    controller: {
      type: Object
    }
  },
  data() {
    return {
      placeholderImage: '',
      actions: {
        openItem: () => this.openItem()
      },
      buttons: {
        open: {
          show: true,
          iconClass: 'fa fa-arrow-up rotate-45'
        }
      },
      icon: {
        primary: 'fa fa-stack-2x small-icon fa-file-invoice-dollar'
      }
    };
  },
  computed: {
    isSelected() {
      return this.$props.cursor === this.currentRecord._id;
    },
    articleImage() {
      if (this.currentRecord.img_path)
        return this.currentRecord.img_path;
      else
        return '';
    },
    shortDesc() {
      if (this.currentRecord.childdesc.length > 42)
        return this.currentRecord.childdesc.substr(0, 42) + '...';
      else
        return this.currentRecord.childdesc;
    }
  }
};
</script>