export default {
  groups: [
    {
      "id": '1536307760241',
      "title": "",
      "color": "#f3f3f3",
      "type": "group",
      "isLastChild": false
    },
    {
      "id": "A60:111:000008100862",
      "type": "group",
      "color": "#4d8b79",
      "title": "Lukas 4M11 Boday and 4M11 Boday and Friend",
      "isLastChild": false
    },
    {
      "id": "A60:111:000004814940",
      "type": "group",
      "color": "#4d8b79",
      "title": "Damiano 1129 Macherndl",
      "isLastChild": false
    },
    {
      "id": "A60:111:000004814272",
      "type": "group",
      "color": "#4d8b79",
      "title": "Alois 1163 Kleinmond",
      "isLastChild": false
    },
    {
      "id": "A60:111:000004815412",
      "type": "group",
      "color": "#4d8b79",
      "title": "Tabea 1155 Taschinger",
      "isLastChild": false
    },
    {
      "id": "A60:111:000008100337",
      "type": "group",
      "color": "#4d8b79",
      "title": "Test Bna",
      "isLastChild": false
    },
    {
      "id": "A60:111:000004815910",
      "type": "group",
      "color": "#4d8b79",
      "title": "Sendung_Test Bna",
      "isLastChild": false
    },
    {
      "id": "A60:111:000008119867",
      "type": "group",
      "color": "#4d8b79",
      "title": "Peter Grübenfels",
      "isLastChild": false
    }
  ],
  items: [
    {
      "id": "AdresseDetailWindow_94870f3e-dd7a-05b0-4314-9a6d2c4c5ccc",
      "type": "item",
      "title": "Adresse Herr Dr. med. et phil. Bernhard3 Affolterererer, Im Hinterhof 12, 33333 Paris, x3x5x7x9x12",
      "parentid": '1536307760241',
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "AdresseDetailWindow_94870f3e-dd7a-05b0-4314-9b6da0ec7340",
      "type": "item",
      "title": "Adresse Herr Dr. med. Rudolf Balmer, Martinsgasse 6, 4051 Basel",
      "parentid": '1536307760241',
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-9c6d888c08c3",
      "type": "item",
      "title": "Versicherter 2061.9175.27 4M11 Boday  Lukas",
      "parentid": "A60:111:000008100862",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-9e6d1595410d",
      "type": "item",
      "title": "Versicherter 2061.9175.27 4M11 Boday  Lukas",
      "parentid": "A60:111:000008100862",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-9e6dd5a9d198",
      "type": "item",
      "title": "Versicherter 2061.9175.27 4M11 Boday  Lukas",
      "parentid": "A60:111:000008100862",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-9f6d783ddf3e",
      "type": "item",
      "title": "Versicherter 2061.9175.27 4M11 Boday  Lukas",
      "parentid": "A60:111:000008100862",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-9f6dc0b1808b",
      "type": "item",
      "title": "Versicherter 2061.9175.27 4M11 Boday  Lukas",
      "parentid": "A60:111:000008100862",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-9f6dd94f5fd3",
      "type": "item",
      "title": "Versicherter 2061.9175.27 4M11 Boday  Lukas",
      "parentid": "A60:111:000008100862",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-a06d1c5b43a4",
      "type": "item",
      "title": "Versicherter 1247.2735.63 1129 Macherndl  Damiano",
      "parentid": "A60:111:000004814940",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-a06d64641cf2",
      "type": "item",
      "title": "Versicherter 2613.3098.17 1163 Kleinmond  Alois",
      "parentid": "A60:111:000004814272",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-a16d302ebd42",
      "type": "item",
      "title": "Versicherter 0627.4248.47 1155 Taschinger  Tabea",
      "parentid": "A60:111:000004815412",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-a26da24e61b2",
      "type": "item",
      "title": "Versicherter 2469.8286.92 Bna  Test",
      "parentid": "A60:111:000008100337",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-a26db8893df6",
      "type": "item",
      "title": "Versicherter 2469.8286.92 Bna  Test",
      "parentid": "A60:111:000008100337",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-a36dad3d436b",
      "type": "item",
      "title": "Versicherter 1383.5117.58 Bna  Sendung_Test",
      "parentid": "A60:111:000004815910",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-a46df85c3346",
      "type": "item",
      "title": "Versicherter 1329.9553.32 Grübenfels  Peter",
      "parentid": "A60:111:000008119867",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-a46d183fa9a0",
      "type": "item",
      "title": "Versicherter 1329.9553.32 Grübenfels  Peter",
      "parentid": "A60:111:000008119867",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-a46d144672f7",
      "type": "item",
      "title": "Versicherter 1329.9553.32 Grübenfels  Peter",
      "parentid": "A60:111:000008119867",
      "isLastChild": false,
      "width": 70.36
    },
    {
      "id": "sStammDetailWindow_94870f3e-dd7a-05b0-4314-a56d19952a32",
      "type": "item",
      "title": "Versicherter 1329.9553.32 Grübenfels  Peter",
      "parentid": "A60:111:000008119867",
      "isLastChild": false,
      "width": 70.36
    }
  ]
};