// define a mixin object
export default {
  methods: {
    /**
         * Method for calling javascript code on the client side
         * @param {string} event The event name eg. $ akioma.offer.testOffer(eventSource)
         */
    callAkiomaCode(event){
      window.app.controller.callAkiomaCode(this.$props.controller, event);
    }
  }
};