<template>
  <card-template
    :active="isSelected"
    :current-record="currentRecord"
    :creation-date="currentRecord.aktimecreated"
    :userkey="currentRecord.usercreatedkey"
    :title="currentRecord.selfkey"
    :actions="actions"
    :icon="icon"
    :buttons="buttons"
  >
    <template #description>
      <p>
        {{ currentRecord.selfdesc }}
      </p>
    </template>
    <template #aditional_info>
      <div v-if="isWordType" class="base-template__word">
        <i class="fa fa-file-word-o" />
      </div>
    </template>
  </card-template>
</template>

<script>
import mixinAkioma from "@/mixins/global-akioma";
import CardTemplate from "@/components/templates/CardTemplate";

export default {
  components: {
    CardTemplate
  },
  mixins: [mixinAkioma],
  props: {
    dataSource: {
      type: Object
    },
    datasourceNamespace: {
      type: Object
    },
    currentRecord: {
      type: Object
    },
    cursor: {
      type: String
    },
    controller: {
      type: Object
    }
  },
  data() {
    return {
      actions: {
        deleteItem: () => this.deleteRelation(),
        openItem: () => this.openItem()
      },
      buttons: {
        delete: {
          show: true,
          iconClass: 'fas fa-trash-alt'
        },
        open: {
          show: true,
          iconClass: 'fa fa-arrow-up rotate-45'
        }
      },
      icon: {
        primary: 'far fa-stack-2x fa-text',
        secondary: 'fal fa-stack-1x fa-cube'
      }
    };
  },
  computed: {
    isSelected() {
      return this.$props.cursor === this.currentRecord._id;
    },
    isWordType() {
      if (this.currentRecord && this.currentRecord.moduletype)
        return this.currentRecord.moduletype.toLowerCase() === "word";

      return false;
    }
  },
  methods: {
    deleteRelation() {
      this.$props.dataSource.dhx.setCursor(this.$props.currentRecord._id);
      window.crm.text.deleteModuleRelation(this.$props.dataSource.dynObject);
    },
    openItem() {
      this.$props.dataSource.dhx.setCursor(this.$props.currentRecord._id);
      this.$props.controller.rowDblClick(this.$props.currentRecord._id);
    }
  }
};
</script>