<template>
  <div class="article-sample-template">
    <div v-if="!imgError" class="div-image">
      <picture>
        <img class="img-article"
             :src="articleImage"
             @error="onImgError()"
        >
      </picture>
    </div>
    <div v-if="articleText.length > 0">
      <table>
        <thead>
          <tr class="tr-head">
            <th />
            <th>Einfache Text</th>
            <th>Formatierter Text</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="text in articleText" :key="text.key">
            <th scope="row" class="th-text-description">
              {{ text.description }}
            </th>
            <td>{{ text.ASCII }}</td>
            <td><span v-html="text.HTML" /></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import mixinAkioma from "@/mixins/global-akioma";

export default {
  mixins: [mixinAkioma],
  props: {
    dataSource: {
      type: Object
    },
    datasourceNamespace: {
      type: Object
    },
    currentRecord: {
      type: Object
    },
    cursor: {
      type: String
    },
    controller: {
      type: Object
    }
  },
  data() {
    return {
      imgError: false,
      articleText: [],
      actions: {
      },
      buttons: {
      },
      icon: {
      }
    };
  },
  computed: {
    articleImage() {
      return akioma.sessionContext.eSwatSessionContext.hostURL + "/web/AppData/image/" + this.currentRecord.selfhdl + '?type=' + 'img.itmdf.main' ;
    }
  },
  created() {
    this.getText();
  },
  methods: {
    onImgError() {
      this.imgError = true;
    },
    getText(){
      const formats = ["ASCII", "HTML"];
      const textKeys = ['txt.itmdf.std', 'txt.itmdf.zaus', 'txt.itmdf.web', 'txt.itmdf.web2', 'txt.itmdf.ver', 'txt.itmdf.inf'];
      const textDesc = ['Artikel Haupttext', 'Artikel Ausschreibungstext', 'Internettext 1', 'Internettext 2', 'Artikel Versions-Info', 'Interne Bemerkung'];

      for (const key in textKeys){
        let txt = {key: textKeys[key], description: textDesc[key], ASCII: "", HTML: ""};

        for (const format in formats){
          $.ajax({
            type: 'GET',
            context: this,
            url: akioma.sessionContext.eSwatSessionContext.hostURL + "/web/AppData/text/" + this.currentRecord.selfhdl,
            data: "language=" + this.currentRecord.datalang + "&format=" + formats[format] + "&type=" + textKeys[key]
          }).then(function (result) {
            if (result !== ""){
              let lFound = false;
              txt[formats[format]] = result;

              for (const i in this.articleText){
                if (this.articleText[i].key == textKeys[key]){
                  this.articleText[i][formats[format]] = result;
                  lFound = true;
                  break;
                }
              }

              if (!lFound)
                this.articleText.push(txt);
            }
          });
        }
      }
    },
  }
};
</script>