<template>
  <div class="vue-taskbar-container">
    <TaskbarMenu :close="close" :select="select" :close-group="closeGroup" />
  </div>
</template>

<script>
import TaskbarMenu from './components/TaskbarMenu.vue';

export default {
  name: 'App',
  components: {
    TaskbarMenu
  },
  props: {
    close: {
      type: Function
    },
    select: {
      type: Function
    },
    closeGroup: {
      type: Function
    }
  }
};
</script>
